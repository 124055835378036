<template>
    <div class="CustToast" :class="type" v-if="showToast">
        <div class="toast">
            <div class="icon">
                <img src="@/assets/exclamat-point.png" />
            </div>
            <div class="desc">
                {{ message }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    /**
     * 自己封装的Toast v0.2
     * params: showToast Boolean 是否激活toast 默认 false
     * params: type String       toast提示类型 共normal success，fail，warning 四个选项 默认normal
     * params: message String    toast消息
     * params: duration Number      toast显示时间 默认 3000ms
     * */
    name: 'CustToast',
    data() {
        return {
            showToast: true,
            type: 'normal',
            message: '消息提示',
            duration: 3000,
        };
    },
    computed: {
        // iconSrc() {
        //     window.console.log("当前类型", this.type);
        //     let tipType = ["normal", "success", "warning", "fail"];
        //     if (tipType.includes(this.type)) {
        //         return require(`@/assets/img/common/${this.type}.svg`)
        //     } else {
        //         throw "Toast type数据只允许为 normal, success, warning, fail 四种其中的一种，默认为normal"
        //     }
        // }
    },
};
</script>

<style scoped>
.CustToast {
    position: absolute;
    width: 95%;
    height: 1.5rem;
    left: 50%;
    top: 2rem;
    background: rgb(233, 233, 235);
    padding: 0.18rem;
    border-radius: 0.15rem;
    transform: translate(-50%, -50%);
    animation: show-toast 0.2s;
    color: #909399;
    overflow: hidden;
    display: flex;
    align-items: center;
    font-size: 0.35rem;
}

@keyframes show-toast {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.toast{
    position: relative;
    z-index: 10000;
}


.success {
    color: #67c23a;
    background: rgb(225, 243, 216);
}

.warning {
    color: #e6a23c;
    background: rgb(250, 236, 216);
}

.fail {
    color: red;
    background: rgb(253, 226, 226);
}

.icon img {
    position: absolute;
    top: 10%;
    left: 1%;
    width: 0.3rem;
    height: 0.3rem;
    margin-top: 0.01rem;
    margin-right: 0.02rem;
}

.desc{
    margin-left: 0.5rem;
}

</style>


class Cookie {
    // save cookie
    static setCookie(name, value, path = '/') {
        var str = name + '=' + escape(value) + `;path=${path}`;
        var date = new Date();
        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

        str += ';expires=' + date.toGMTString();
        document.cookie = str;
    }

    static deleteAllCookies() {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }

    static deleteCookie(name) {
        console.log('to delete cookie:' + name);
        var cookies = document.cookie.split('; ');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var cur = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (cur == name) {
                console.log('do delete cookie:' + name);
                document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
            }
        }
    }

    // read cookie
    static getCookie(name) {
        var arr,
            reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
        if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
        else return null;
    }
}
export default Cookie;

/* eslint-disable prettier/prettier */
export default {
    '{0} invited {1} friends, get {2} Rs. reward': '{0} invited {1} friends, get {2} Rs. reward',
    '{0} invited {1} friends, got {2} Rs. commission': '{0} invited {1} friends, got {2} Rs. commission',
    'START NOW': 'START NOW',
    'Already registered, log in now': 'Already registered, log in now',
    'RULE DESCRIPTION': 'RULE DESCRIPTION',
    'HOW TO MAKE MONEY?': 'HOW TO MAKE MONEY?',
    'The dealer can promote the game, and the promotion of the game revenue generated by the user, the dealer can get a commission, the more users, the greater the revenue.': 'The dealer can promote the game, and the promotion of the game revenue generated by the user, the dealer can get a commission, the more users, the greater the revenue.',
    'How to promote the game?': 'HOW TO SHARE THIS GAME?',
    'Apply to become a promoter': 'Apply to become a promoter',
    'Send an invitation link to your friends or target audience.': 'Send an invitation link to your friends or target audience.',
    'Click the promotion link to download and install the game': 'Click the promotion link to download and install the game',
    'Referral Link': 'Referral Link',
    'Copy': 'Copy',
    'Share your link': 'Share your link',
    'More': 'More',
    'REFERRAL BONUS': 'REFERRAL BONUS',
    'Rs': 'Rs',
    'MY': 'MY',
    'AGENT': 'AGENT',
    'Total commission': 'Total commission',
    'Withdraw': 'Withdraw',
    'Time': 'Time',
    'DATE': 'DATE',
    'NEW INSTALLATION': 'NEW INSTALLATION',
    'ACTIVE DAY': 'ACTIVE DAY',
    'INCOME': 'INCOME',
    '(Must be installed through the promotion link, cannot be transmitted through sharelt)': '(Must be installed through the promotion link, cannot be transmitted through sharelt)',
    'Only valid for Android users, iOS is temporarily not supported': 'Only valid for Android users, iOS is temporarily not supported',
    'Withdrawal instructions': 'Withdrawal instructions',
    'If you would like to withdraw the money you have earned, the minimum withdrawal amount under this Referral Program should be reached RS.1000. Please note that the income amount will be cleared after withdrawing.': 'If you would like to withdraw the money you have earned, the minimum withdrawal amount under this Referral Program should be reached RS.1000. Please note that the income amount will be cleared after withdrawing.',
    'There are several first payment methods that need to be provid when withdrawing cash.': 'There are several first payment methods that need to be provid when withdrawing cash.',
    'The funds efficiency varies from payment methods, which usually takes 3-7 business days.': 'The funds efficiency varies from payment methods, which usually takes 3-7 business days.',
    'EMAIL': 'EMAIL',
    'PHONE NUMBER': 'PHONE NUMBER',
    'CAPTCHA': 'CAPTCHA',
    'LOG IN': 'LOG IN',
    'SIGN UP': 'SIGN UP',
    'Forgot password ?': 'Forgot password ?',
    'PASSWORD': 'PASSWORD',
    'CONFIRM PASSWORD': 'CONFIRM PASSWORD',
    'Log in with already account': 'Log in with already account',
    'Forget Password': 'Forget Password',
    'VERIFICATION': 'VERIFICATION',
    'Email retrieval': 'Email retrieval',
    'Phone number retrieval': 'Phone number retrieval',
    'NEW PASSWORD': 'NEW PASSWORD',
    'CONFIRM NEW PASSWORD': 'CONFIRM NEW PASSWORD',
    'Log in with message': 'Log in with message',
    'Send the code': 'Send the code',
    'invite friends': 'invite friends',
    'The last {0} days': 'The last {0} days',
    'Last {0} days': 'Last {0} days',
    'Cancel': 'Cancel',
    'Confirm': 'Confirm',
    'Verify code was sent to your phone number': 'Verify code was sent to your phone number',
    'Failed to send verify code sms, please check your phone number and retry.': 'Failed to send verify code sms, please check your phone number and retry.',
    'Your verify code mismatch with phone numer': 'Your verify code mismatch with phone numer',
    'This phone numer has been registered!': 'This phone numer has been registered!',
    'Something went wrong with server, please retry later.': 'Something went wrong with server, please retry later.',
    'Your phone number or password is incorrect, check and retry.': 'Your phone number or password is incorrect, check and retry.',
    'Your password has been changed.': 'Your password has been changed.',
    'Your phone number is incorrect, check and retry.': 'Your phone number is incorrect, check and retry.',
    'Login info expires, please login.': 'Login info expires, please login.',
    'Balance is not enough for withdraw. Earning more and try again.': 'Balance is not enough for withdraw. Earning more and try again.',
    'Try too many withdraws in 24 hours. Earning more and try again.': 'Try too many withdraws in 24 hours. Earning more and try again.',
    'Withdraw service failed temporarily. Earning more and try again.': 'Withdraw service failed temporarily. Earning more and try again.',
    'Your promotional link copied.': 'Referral link copied.',
    'Your referral link copied.': 'Referral link copied.',
    'Share failed or canceled': 'Share failed or canceled',
    'Something went wrong with sharing.': 'Something went wrong with sharing.',
    'Withdraw success and go to cash check.': 'Withdraw success and go to cash check.',
    'Install': 'Install',
    'Recharge in-game': 'Recharge in-game',
    'Double': 'Double',
    'WhatsApp': 'WhatsApp',
    'Facebook': 'Facebook',
    'Messenger': 'Messenger',
    'How to earn more money?': 'How to earn more money?',
    'Copy the unique URL link on your personal page to others, and all users registered by this link will be your agents.': 'Copy the unique URL link on your personal page to others, and all users registered by this link will be your agents.',
    'Type your phone number': 'Type your phone number',
    'At least {0} can be withdrawn': 'At least {0} can be withdrawn',
    'Withdraw in progress, check and handle it first.': 'Withdraw in progress, check and handle it first.',
    'At most {0} withdraw in 24 hours': 'At most {0} withdraw in 24 hours',
    'Invalid mobile phone number': 'Invalid phone number',
    'Phone number required': 'Phone number required',
    'OTP required': 'OTP required',
    'Download': 'Download',
    'Processing': 'Processing',
    'Failed': 'Failed',
    'Succeeded': 'Succeeded',
    'success': 'Succeeded',
    'fail': 'Failed',
    'pending': 'Processing',
    '₹': '₹',
    'Welcome to Taurus, and receive ₹ {0}!': 'Welcome to Taurus, and receive ₹ {0}!',
    'Refer & Earn': 'Refer & Earn',
    'Current Balance': 'Current Balance',
    'Discounts Store': 'Discounts Store',
    'Rules': 'Rules',
    'My Commission': 'My Commission',
    'Date': 'Date',
    'The withdrawal has been successful and money will be sent to you within 7 working days.': 'The withdrawal has been successful and money will be sent to you within 7 working days.',
    'You\'ve reached the maximum number of attempts to get OTP today. Please try again 24 hours later.': 'You\'ve reached the maximum number of attempts to get OTP today. Please try again 24 hours later.',
    'No chance to obtain OTP today. Please try it after 24 hours.':'No chance to obtain OTP today. Please try it after 24 hours.',
    'Sharing successful': 'Sharing successful',
    'The system is upgrading, please try later.': 'The system is upgrading, please try later.',
    'Tips: Send to a specific user': 'Tips: Send to a specific user',
    '* Invalid ID': '* Invalid ID',
    'Payee (Mobile Number)': 'Payee (Mobile Number)',
    'Type User ID': 'Type User ID',
    'Type Amount': 'Type Amount',
    "Can't recharge for yourself": "Can't recharge for yourself",
    'NAME': 'NAME',
    'Type your name': 'Type your name',
    'Notice': 'Notice',
    'Got it': 'Got it',
    'Please choose BANK ACCOUNT to withdraw. Because of policy from third party payment organization, currently UPI is not supported.': 'Please choose BANK ACCOUNT to withdraw. Because of policy from third party payment organization, currently UPI is not supported.',
    'Withdraw Amount (Rs)': 'Withdraw Amount (Rs)',
    'Current Amount (Rs)': 'Current Amount (Rs)',
    'Choose a bank': 'Choose a bank',
    'Withdrawal Amount': 'Withdrawal Amount',
    'WITHDRAW': 'WITHDRAW',
    'Adding a bank account': 'Adding a bank account',
    'Why am I not able to withdraw all current balance?': 'Why am I not able to withdraw all current balance?',
    'Because the friends you invited are not active in game, so currently some of your commission is not withdrawable.': 'Because the friends you invited are not active in game, so currently some of your commission is not withdrawable.',
    'If they are active in game, the related commission in your current balance will be converted to your withdrawable balance.': 'If they are active in game, the related commission in your current balance will be converted to your withdrawable balance.',
    '4 factors determine whether your friends are an active game user': '4 factors determine whether your friends are an active game user',
    '1. Daily online time': '1. Daily online time',
    '2. Daily recharge amount': '2. Daily recharge amount',
    '3. Consecutive login days': '3. Consecutive login days',
    '4. Game rank': '4. Game rank',
    'Withdraw Details': 'Withdraw Details',
    'Policy objective':'Policy objective',
    "Taurus respects your privacy and assures you that any information provided by you to Taurus is protected and will be dealt with according to this Policy and the applicable laws. To avail the services offered on https://taurus.cash Taurus网站域名 (hereinafter singly referred as the 'Website' ) , you may have to provide certain information to us. This Policy provides the procedure followed by Taurus to gather, uses, store, discloses and manages users' personal data. Taurus only collects personal data for the purpose of verifying user accounts, maintaining the accounts of the users, completing transactions of the users and for analyzing user behavior and requirements.":
    "Taurus respects your privacy and assures you that any information provided by you to Taurus is protected and will be dealt with according to this Policy and the applicable laws. To avail the services offered on https://www.taurus.cash/ (hereinafter singly referred as the 'Website' ) , you may have to provide certain information to us. This Policy provides the procedure followed by Taurus to gather, uses, store, discloses and manages users' personal data. Taurus only collects personal data for the purpose of verifying user accounts, maintaining the accounts of the users, completing transactions of the users and for analyzing user behavior and requirements.",

    "What is included in personal data?":"What is included in personal data?",
    "User's personal data includes the following types of personal data or information":"User's personal data includes the following types of personal data or information",
    
    "Sensitive Personal Data":"Sensitive Personal Data",
   "Account password Financial information such as Bank account or credit card or debit card or other payment instrument details":"Account password Financial information such as Bank account or credit card or debit card or other payment instrument details",


    "Other Personal Information": "Other Personal Information",
    "Date of birth":"Date of birth",
    "Telephone number":"Telephone number",
    "The IP address of your computer, browser type and language.":"The IP address of your computer, browser type and language.",
    "The date and the time during which you accessed the site.":"The date and the time during which you accessed the site.",
    "The address of the website which you may have used to link to any Website":"The address of the website which you may have used to link to any Website",
    "Your photograph for testimonials and other promotions.":"Your photograph for testimonials and other promotions.",
    "Such other information that is defined as sensitive personal data or information in law.":"Such other information that is defined as sensitive personal data or information in law.",
    "You shall be entitled to update the information provided by you by contacting us at bethanywhite1172@gmail.com. Your information shall be updated subject to reasonable checks and confirmation by Taurus.":
    "You shall be entitled to update the information provided by you by contacting us at bethanywhite1172@gmail.com. Your information shall be updated subject to reasonable checks and confirmation by Taurus.",


    "User Consents":"User Consents",

    "Consent for use of Sensitive Personal Data and Other Personal Information":"Consent for use of Sensitive Personal Data and Other Personal Information",
    
    "All users of Taurus consent to the use of Sensitive Personal Data and Other Personal Information for the purpose stated in this policy. We restrict access of personal information to our employees, contractors and agents and only allow access to those who need to know that information in order to process it on our behalf.":
    "All users of Taurus consent to the use of Sensitive Personal Data and Other Personal Information for the purpose stated in this policy. We restrict access of personal information to our employees, contractors and agents and only allow access to those who need to know that information in order to process it on our behalf.",

    "Taurus may also use software applications for website traffic analysis and to gather statistics, used for advertising and for determining the efficacy and popularity of Taurus among others.":
    "Taurus may also use software applications for website traffic analysis and to gather statistics, used for advertising and for determining the efficacy and popularity of Taurus among others.",

    "Consent to use of Cookies": "Consent to use of Cookies",

    "What is a Cookie: A cookie is a small text file that uniquely identifies your browser. You understand that when you visit the Website, cookies may be left in your computer. The cookies assigned by the servers of Taurus may be used to personalize your experience on the Website. Additionally, cookies may also be used for authentication, game management, data analysis and security purposes.":
    "What is a Cookie: A cookie is a small text file that uniquely identifies your browser. You understand that when you visit the Website, cookies may be left in your computer. The cookies assigned by the servers of Taurus may be used to personalize your experience on the Website. Additionally, cookies may also be used for authentication, game management, data analysis and security purposes.",

    "Data Security":"Data Security",

    "We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data. Taurus has a comprehensive information security program and information security policies, which contain managerial, technical, operational and physical security control measures adopted by Taurus for the protection of Sensitive Personal Date and Other Personal Information.":
    "We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data. Taurus has a comprehensive information security program and information security policies, which contain managerial, technical, operational and physical security control measures adopted by Taurus for the protection of Sensitive Personal Date and Other Personal Information.",


    "Information gathered by Taurus is stored securely using several information security applications including firewalls. However, security is always relative and Taurus cannot guarantee that its security measures are absolute and cannot be breached. Data which is transmitted over the Internet is inherently exposed to security risks or threats. For instance, information transmitted via chat or email can be compromised and used by others. Therefore, Taurus cannot guarantee any security for such information in the course of transmission through the internet infrastructure or any unsolicited disclosures made by any user availing the services of the Website.":
    "Information gathered by Taurus is stored securely using several information security applications including firewalls. However, security is always relative and Taurus cannot guarantee that its security measures are absolute and cannot be breached. Data which is transmitted over the Internet is inherently exposed to security risks or threats. For instance, information transmitted via chat or email can be compromised and used by others. Therefore, Taurus cannot guarantee any security for such information in the course of transmission through the internet infrastructure or any unsolicited disclosures made by any user availing the services of the Website.",

    "When you register with Taurus, your account is protected by means of login information which includes a username and a password that is known only to you. Therefore, you should not provide your personal information to anyone whosoever and breach hereof constitutes violation of this Policy and can also result in closure of account in certain cases.":
    "When you register with Taurus, your account is protected by means of login information which includes a username and a password that is known only to you. Therefore, you should not provide your personal information to anyone whosoever and breach hereof constitutes violation of this Policy and can also result in closure of account in certain cases.",

    "Taurus has a policy of not sharing any personally identifiable information with anyone other than entities specifically authorized by Taurus which may include advertisers and sponsors of Taurus. However,Taurus may use your name, photo, Login ID and the state from where you are participating when announcing the results of any contests run on the Website. Such contests are further governed by terms and conditions which shall be available on the Websites as and when such a contest is run on any Website.Taurus conducts periodic analysis and survey of the traffic to https://taurus.cash Taurus官网域名 for market research and advertising purposes. Taurus reserves the right to share your registration information with Taurus appointed market research and advertising companies or firms from time to time for the said purposes. Taurus may also use cumulative non-personal information for auditing and analysis purposes with the aim of improving its services.":
    "Taurus has a policy of not sharing any personally identifiable information with anyone other than entities specifically authorized by Taurus which may include advertisers and sponsors of Taurus. However,Taurus may use your name, photo, Login ID and the state from where you are participating when announcing the results of any contests run on the Website. Such contests are further governed by terms and conditions which shall be available on the Websites as and when such a contest is run on any Website.Taurus conducts periodic analysis and survey of the traffic to https://www.taurus.cash for market research and advertising purposes. Taurus reserves the right to share your registration information with Taurus appointed market research and advertising companies or firms from time to time for the said purposes. Taurus may also use cumulative non-personal information for auditing and analysis purposes with the aim of improving its services.",

    "Data Retention policy":"Data Retention policy",
    "Taurus shall retain the information provided by the users for such period of time as is required to fulfil the purposes for which such information is collected, as outlined in this Privacy Policy, subject to such longer time periods of retention as may be required under any applicable laws.":"Taurus shall retain the information provided by the users for such period of time as is required to fulfil the purposes for which such information is collected, as outlined in this Privacy Policy, subject to such longer time periods of retention as may be required under any applicable laws.",
    
    "Exclusions":"Exclusions",

    "Taurus may share Sensitive Personal Data and Other Personal Information if sharing of such information is necessary":
    "Taurus may share Sensitive Personal Data and Other Personal Information if sharing of such information is necessary",
    "to comply with legal processes or governmental request":"to comply with legal processes or governmental request",
    "to enforce the Terms of Service and this Privacy Policy":"to enforce the Terms of Service and this Privacy Policy",
    "for prevention of fraud":"for prevention of fraud",
    "for issues involving information security, or":"for issues involving information security, or",
    "to protect: your rights; rights of Taurus; and rights of the general public.":"to protect: your rights; rights of Taurus; and rights of the general public.",

    "Limitation of Liability":"Limitation of Liability",

    "Taurus confirms that this Privacy Policy is only a description of its operation regarding user information. This Policy is not intended to and does not create any legal rights in your favour or in the favour of any other person. Taurus reserves the right to change this Policy at any time without giving you prior notice. The liability of Taurus shall be limited to removal of Sensitive Personal Data from the system of the Websites and removal of personally identifiable elements of the Other Personal Information. Notwithstanding anything to the contrary contained in this Policy and elsewhere, the aggregate liability of Taurus for all claims for damages pursuant to provisions of services on the Website, including claims in respect to the violation of this Policy, shall be limited to the aggregate maximum amount of liability as provided in the Terms of Service.":
    "Taurus confirms that this Privacy Policy is only a description of its operation regarding user information. This Policy is not intended to and does not create any legal rights in your favour or in the favour of any other person. Taurus reserves the right to change this Policy at any time without giving you prior notice. The liability of Taurus shall be limited to removal of Sensitive Personal Data from the system of the Websites and removal of personally identifiable elements of the Other Personal Information. Notwithstanding anything to the contrary contained in this Policy and elsewhere, the aggregate liability of Taurus for all claims for damages pursuant to provisions of services on the Website, including claims in respect to the violation of this Policy, shall be limited to the aggregate maximum amount of liability as provided in the Terms of Service.",

    //PC-terms-of-use.vue
    "Introduction":"Introduction",
    "Your use of the products and/or services on the Portal (hereinafter referred to as 'Services') currently offered or to be offered in future by Taurus Limited (hereinafter referred to as 'Taurus'), its subsidiaries, affiliates, licensors, associates and partners through the Portal( which is https://taurus.cash, hereinafter referred as 'Website'), are all subject to and governed by these Terms of Service.":
    "Your use of the products and/or services on the Portal (hereinafter referred to as 'Services') currently offered or to be offered in future by Taurus Limited (hereinafter referred to as 'Taurus'), its subsidiaries, affiliates, licensors, associates and partners through the Portal( which is https://taurus.cash, hereinafter referred as 'Website' ), are all subject to and governed by these Terms of Service.",


    "You understand that the Terms will be binding on you. You agree that Services offered on the website can be accessed only in accordance with the Terms and you shall be responsible to comply with the Terms at all times. You are responsible to be aware of and agree to abide by the Terms as published and periodically amended or modified by Taurus.":
    "You understand that the Terms will be binding on you. You agree that Services offered on the website can be accessed only in accordance with the Terms and you shall be responsible to comply with the Terms at all times. You are responsible to be aware of and agree to abide by the Terms as published and periodically amended or modified by Taurus.",

    "If any of the Terms are determined to be unlawful, invalid, void or unenforceable for any reason by any judicial or quasi - judicial body in India, it will not affect the validity and enforceability of the remaining Terms. Our failure or delay to act or exercise any right or remedy with respect to a breach of any of the Terms by you shall not be construed as a waiver of our right to act with respect to the said breach or any prior, concurrent, subsequent or similar breaches.":
    "If any of the Terms are determined to be unlawful, invalid, void or unenforceable for any reason by any judicial or quasi - judicial body in India, it will not affect the validity and enforceability of the remaining Terms. Our failure or delay to act or exercise any right or remedy with respect to a breach of any of the Terms by you shall not be construed as a waiver of our right to act with respect to the said breach or any prior, concurrent, subsequent or similar breaches.",

    "Applicability":"Applicability",
    "Before registering with us, you should carefully read and review these terms provided on https://taurus.cash website which are applicable to all services on the portal and also the product-specific conditions and rules applicable to specific offerings.":
    "Before registering with us, you should carefully read and review these terms provided on https://taurus.cash website which are applicable to all services on the portal and also the product-specific conditions and rules applicable to specific offerings.",
    
    "Game Promotion Services":"Game Promotion Services",

    "All game promotion and practice organized on the Websites are collectively referred as 'Game Promotion'. We reserve the right to decide commission percentage for game promoters. We also reserve the right to stop any game promotions with forbidden and illegal behaviors.":
    "All game promotion and practice organized on the Websites are collectively referred as 'Game Promotion'. We reserve the right to decide commission percentage for game promoters. We also reserve the right to stop any game promotions with forbidden and illegal behaviors.",
    
    "Game promoter representations":"Game promoter representations",
    "Any information provided by you to us, whether at the stage of registration or during anytime subsequently, should be complete and truthful.":
    "Any information provided by you to us, whether at the stage of registration or during anytime subsequently, should be complete and truthful.",

    "You represent that you are 18 years of age or older to participate in any game promotion and are also otherwise competent to enter into transactions with other users and us. With full knowledge of the facts and circumstances surrounding promotions, you are voluntarily participating in the promotions and assume all responsibility for and risk resulting from your participation.":
    "You represent that you are 18 years of age or older to participate in any game promotion and are also otherwise competent to enter into transactions with other users and us. With full knowledge of the facts and circumstances surrounding promotions, you are voluntarily participating in the promotions and assume all responsibility for and risk resulting from your participation.",
    
    "You represent that you have the experience and the requisite skills required to participate in the promotions and that you are aware of any physical or mental condition that would impair your capability to fully participate in the promotions. You further acknowledge that you are solely responsible for any consequence resulting from you participating in the promotions or being associated with the promotions or around the promotions.":
    "You represent that you have the experience and the requisite skills required to participate in the promotions and that you are aware of any physical or mental condition that would impair your capability to fully participate in the promotions. You further acknowledge that you are solely responsible for any consequence resulting from you participating in the promotions or being associated with the promotions or around the promotions.",

	
    "You shall not hold us responsible for not being able to pay you commission for which you may be eligible to participate. This includes but is not limited to situations where you are unable to share your referral link to more users and you are not unable to activate users to recharge in the game.":
    "You shall not hold us responsible for not being able to pay you commission for which you may be eligible to participate. This includes but is not limited to situations where you are unable to share your referral link to more users and you are not unable to activate users to recharge in the game.",

    "Promoter Account Creation & Operation":"Promoter Account Creation & Operation",

    "By completing the online registration process on the website, you confirm your acceptance of the Terms.":"By completing the online registration process on the website, you confirm your acceptance of the Terms.",

	"By registering on our website, you agree to receive all communication from us including promotional messages, voice call, Email and Push Notifications. You may withdraw your consent by sending an email to bethanywhite1172@gmail.com as the case may be.":"By registering on our website, you agree to receive all communication from us including promotional messages, voice call, Email and Push Notifications. You may withdraw your consent by sending an email to bethanywhite1172@gmail.com as the case may be.",

    "During the registration process, you will be required to choose a login name and a password in addition to providing some other information which may not be mandatory. Additionally, you may be required to give further personal information for your user account verification and/or for adding cash to your user account. You must give us the correct details in all fields requiring your personal information, including, without limitation, your name, postal address, email address, telephone number(s) etc. You undertake that you will update this information and keep it current.":
    "During the registration process, you will be required to choose a login name and a password in addition to providing some other information which may not be mandatory. Additionally, you may be required to give further personal information for your user account verification and/or for adding cash to your user account. You must give us the correct details in all fields requiring your personal information, including, without limitation, your name, postal address, email address, telephone number(s) etc. You undertake that you will update this information and keep it current.",

	 
    "You acknowledge that we may, at any time, required to verify the correctness of this information and in order to do so may require additional documentary proof from you, failing which we reserve the right to suspend or terminate your registration on the Website.":
    "You acknowledge that we may, at any time, required to verify the correctness of this information and in order to do so may require additional documentary proof from you, failing which we reserve the right to suspend or terminate your registration on the Website.",

    "Any information provided by you to us should be complete and truthful to the best of your knowledge. We are not obliged to cross check or verify information provided by you and we will not take any responsibility for any outcome or consequence as a result of you providing incorrect information or concealing any relevant information from us.":
    "Any information provided by you to us should be complete and truthful to the best of your knowledge. We are not obliged to cross check or verify information provided by you and we will not take any responsibility for any outcome or consequence as a result of you providing incorrect information or concealing any relevant information from us.",

	
    "You understand that it is your responsibility to protect the information you provide on the Websites including but not limited to your Username, Password, Email address, Contact Details and Mobile number. We will not ask for your user account login password which is only to be entered at the time of login. At no other time should you provide your user account information to any user logged in on the Websites or elsewhere. You undertake that you will not allow / login and then allow, any other person to play from your user account using your username. You specifically understand and agree that we will not incur any liability for information provided by you to anyone which may result in your user account on the Websites being exposed or misused by any other person.":
    "You understand that it is your responsibility to protect the information you provide on the Websites including but not limited to your Username, Password, Email address, Contact Details and Mobile number. We will not ask for your user account login password which is only to be entered at the time of login. At no other time should you provide your user account information to any user logged in on the Websites or elsewhere. You undertake that you will not allow / login and then allow, any other person to play from your user account using your username. You specifically understand and agree that we will not incur any liability for information provided by you to anyone which may result in your user account on the Websites being exposed or misused by any other person.",

    "User restrictions":"User restrictions",

    "Anti-Cheating and Anti-Collusion":"Anti-Cheating and Anti-Collusion",

    "You undertake the responsibilities in the promotions in which you have registered/joined. You shall not add unauthorized components, create or use cheats, exploits, bots, hacks or any other third-party software designed to modify the Websites or use any third-party software that intercepts, mines or otherwise collects information from or through the Websites or through any Services. Any attempt to employ any such external assistance is strictly prohibited.":
    "You undertake the responsibilities in the promotions in which you have registered/joined. You shall not add unauthorized components, create or use cheats, exploits, bots, hacks or any other third-party software designed to modify the Websites or use any third-party software that intercepts, mines or otherwise collects information from or through the Websites or through any Services. Any attempt to employ any such external assistance is strictly prohibited.",

    "Formation of teams for the purpose of collusion between you and any other user(s) for participating in the promotions organized on the Websites or any other form of cheating is strictly prohibited.":
    "Formation of teams for the purpose of collusion between you and any other user(s) for participating in the promotions organized on the Websites or any other form of cheating is strictly prohibited.",


    "When collusion or cheating is detected on https://taurus.cash, we shall take further appropriate action against offending users in terms hereof including banning access to the Portal and any or all Services.":
    "When collusion or cheating is detected on https://taurus.cash, we shall take further appropriate action against offending users in terms hereof including banning access to the Portal and any or all Services.",

    "Money Laundering":"Money Laundering",

    
    "You are prohibited from doing any activity on the Website that may be construed as money laundering, including, without limitation, attempting to withdraw cash from unutilized cash added through credit cards or deliberately losing money to a certain player(s).":
    "You are prohibited from doing any activity on the Website that may be construed as money laundering, including, without limitation, attempting to withdraw cash from unutilized cash added through credit cards or deliberately losing money to a certain player(s).",
    
    "Anti-SPAMMING":"Anti-SPAMMING",

    "Sending SPAM emails or any other form of unsolicited communication for obtaining registrations on the Website to benefit from any of our promotional program or for any other purpose is strictly prohibited.":
    "Sending SPAM emails or any other form of unsolicited communication for obtaining registrations on the Website to benefit from any of our promotional program or for any other purpose is strictly prohibited.",

    "You shall not purchase, sell, trade, rent, lease, license, grant a security interest in, or transfer your user account, Content, currency, points, standings, rankings, ratings, or any other attributes appearing in, originating from or associated with the website.":
    "You shall not purchase, sell, trade, rent, lease, license, grant a security interest in, or transfer your user account, Content, currency, points, standings, rankings, ratings, or any other attributes appearing in, originating from or associated with the website.",

    "Any form of fraudulent activity including, attempting to use or using any other person's credit card(s), debit cards, net-banking usernames, passwords, authorization codes, prepaid cash cards, mobile phones for adding cash to your user account is strictly prohibited.":
    "Any form of fraudulent activity including, attempting to use or using any other person's credit card(s), debit cards, net-banking usernames, passwords, authorization codes, prepaid cash cards, mobile phones for adding cash to your user account is strictly prohibited.",

    "Winnings, bonuses and prizes are unique to the player and are non-transferable. In the event you attempt to transfer any winnings, bonuses or prizes, these will be forfeited.":
    "Winnings, bonuses and prizes are unique to the player and are non-transferable. In the event you attempt to transfer any winnings, bonuses or prizes, these will be forfeited.",
    
    "If you are an officer, director, employee, consultant or agent of Taurus or a relative of such persons ('Associated Person'), you are not permitted to play either directly or indirectly, any Games which entitle you to any prize on the Portal, other than in the course of your engagement with us. For these purposes, the term 'relative' shall include spouse and financially dependent parents and, children.":
    "If you are an officer, director, employee, consultant or agent of Taurus or a relative of such persons ('Associated Person'), you are not permitted to play either directly or indirectly, any Games which entitle you to any prize on the Portal, other than in the course of your engagement with us. For these purposes, the term 'relative' shall include spouse and financially dependent parents and, children.",

    "Content":"Content",
    "All content and material on the website including but not limited to information, images, marks, logos, designs, pictures, graphics, text content, hyperlinks, multimedia clips, animation, games and software (collectively referred to as 'Content'), whether or not belonging to Taurus, are protected by applicable intellectual property laws.":
    "All content and material on the website including but not limited to information, images, marks, logos, designs, pictures, graphics, text content, hyperlinks, multimedia clips, animation, games and software (collectively referred to as 'Content'), whether or not belonging to Taurus, are protected by applicable intellectual property laws. ",

    "The Portal may contain information about or hyperlinks to third parties. In such a cases, we are not responsible in any manner and do not extend any express or implied warranty to the accuracy, integrity or quality of the content belonging to such third party websites or apps. If you rely on any third party Content posted on any website or app which does not belong to Taurus, you may do so solely at your own risk and liability.":
    "The Portal may contain information about or hyperlinks to third parties. In such a cases, we are not responsible in any manner and do not extend any express or implied warranty to the accuracy, integrity or quality of the content belonging to such third party websites or apps. If you rely on any third party Content posted on any website or app which does not belong to Taurus, you may do so solely at your own risk and liability.",

    "If you visit any third party website or app through a third party Content posted on the website, you will be subject to terms and conditions applicable to such third party website or app. We neither control nor are responsible for content on such third-party websites or apps. The fact of a link existing on our website to a third-party website or app is not an endorsement of that website or app by us.":
    "If you visit any third party website or app through a third party Content posted on the website, you will be subject to terms and conditions applicable to such third party website or app. We neither control nor are responsible for content on such third-party websites or apps. The fact of a link existing on our website to a third-party website or app is not an endorsement of that website or app by us.",
    
    "Complaints, Grievances & disputes":"Complaints, Grievances & disputes",


    "If you have a complaint, you should in the first instance contact the customer support team at bethanywhite1172@gmail.com, as the case may be or write to us following the procedure given in the respective Contact Us section on the relevant Websites. Complaints should be made as soon as possible after circumstances arise that cause you to have a complaint.":
    "If you have a complaint, you should in the first instance contact the customer support team at bethanywhite1172@gmail.com, as the case may be or write to us following the procedure given in the respective Contact Us section on the relevant Websites. Complaints should be made as soon as possible after circumstances arise that cause you to have a complaint.",

    "You accept that any complaints and disputes are and remain confidential both whilst a resolution is sought and afterwards. You agree that you shall not disclose the existence, nature or any detail of any complaint or dispute to any third party.":
    "You accept that any complaints and disputes are and remain confidential both whilst a resolution is sought and afterwards. You agree that you shall not disclose the existence, nature or any detail of any complaint or dispute to any third party.",

    "Taurus shall make efforts to resolve complaints within reasonable time. Our decision on complaints shall be final and binding on you.":
    "Taurus shall make efforts to resolve complaints within reasonable time. Our decision on complaints shall be final and binding on you.",

    "Modifications and alterations":"Modifications and alterations",

    "We may alter or modify the Terms at any time without giving prior notice to you. We may choose to notify of some changes in the Terms either by email or by displaying a message on the website; however, our notification of any change shall not waive your obligation to keep yourself updated about the changes in the Terms.":
    "We may alter or modify the Terms at any time without giving prior notice to you. We may choose to notify of some changes in the Terms either by email or by displaying a message on the website; however, our notification of any change shall not waive your obligation to keep yourself updated about the changes in the Terms.",
    

    "keep me signed in":"keep me signed in",
    "Withdrawable Amount":"Withdrawable Amount",
    "Converting into Withdrawable Amount":"Converting into Withdrawable Amount",
    "Total Amount":"Total Amount",
    "How to increase withdrawable amount?":"How to increase withdrawable amount?",
    "Part of your commission has not been converted into withdrawable amount yet because of your inactive referrals. Once those referrals are active in game, your commission will be converted into withdrawable amount.":"Part of your commission has not been converted into withdrawable amount yet because of your inactive referrals. Once those referrals are active in game, your commission will be converted into withdrawable amount.",
    "How to make my referrals active?":"How to make my referrals active?",
    "1.Play game daily":"1.Play game daily",
    "2.Recharge in game":"2.Recharge in game",
    "3.Log into game for continuous days":"3.Log into game for continuous days",
    "4.High game rank and rewards":"4.High game rank and rewards",
    "Why is the minimum withdrawal amount different every time?":"Why is the minimum withdrawal amount different every time?",
    "In order to make withdrawal easy for new users, Taurus provides minimum withdrawal amount with an increasingly fixed value for five times, when your withdrawable commission is less than ₹1000.":"In order to make withdrawal easy for new users, Taurus provides minimum withdrawal amount with an increasingly fixed value for five times, when your withdrawable commission is less than ₹1000.",
    "Everything you need to know!":"Everything you need to know!",
    "Withdraw Method":"Withdraw Method",
    "Guess you want to know":"Guess you want to know",
    "Use only bank account to withdraw due to bank system maintenance.":"Use only bank account to withdraw due to bank system maintenance.",
    "Time period for bank to complete all procedures varies.":"Time period for bank to complete all procedures varies.",
    "Withdrawal may not supported in the late afternoon and night, due to payment gateway maintenance.": "Withdrawal may not supported in the late afternoon and night, due to payment gateway maintenance.",
    "This maintenance last till next Tuesday.": "This maintenance last till next Tuesday.",
    "All": "All",
    "Banking services are busy, please try again later.": "Banking services are busy, please try again later."
};


import axios from 'axios';
import Cookie from '@/utils/cookie';
import { production } from '../config';

// create an axios instance
const http = axios.create({
    // baseURL: 'https://landscape-api.taurus.cash/awclient',
    baseURL: 'https://landscape-api.agent61.com/awclient',
    // baseURL: 'https://landscape-api.r2024e.com/awclient',
    timeout: 30000, // request timeout
    responseType: "json",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      // "Host": "api.tcvg.pw",
      // "Origin": "api.tcvg.pw",
    }
  });

http.interceptors.request.use(config => {
    var token = window.localStorage.getItem('token');
    if (!token) {
        token = Cookie.getCookie('token') || '';
    }
    if (token) {
        config.headers['token'] = token;
    }
    if (!production) {
      config.url = config.url.replace('', '');
    }
    return config;
});

export default http;

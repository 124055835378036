<style>
* {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    box-sizing: border-box;
    font-family: PingFang SC;
}
html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
}

ul,
li,
p {
    margin: 0;
    padding: 0;
    list-style: none;
}

p {
    hyphens: auto;
}

img {
    display: block;
}

button {
    border: none;
    outline: none;
}

.ell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#app {
    line-height: 1;
    font-family: 'PingFang SC', 'STHeitiSC-Light', 'Helvetica-Light', arial, sans-serif, 'Droid Sans Fallback';
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-size: 0.32rem;
    min-height: 100%;
    height: 100%;
}

/* 处理input type = number的上下箭头 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
input[type=number] {
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}

.ant-message {
    top: 60% !important;
    font-size: 0.32rem !important;
    width: 80% !important;
    margin: 0 10% !important;
    z-index: 100000000 !important;
}
.ant-message-notice-content {
    padding: 0.1rem 0.16rem !important;
    background-color: #ddd !important;
}
.ant-message-custom-content {
    font-size: 0.28rem;
    line-height: 0.28rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-message .anticon {
    font-size: 0.24rem !important;
}
.ant-radio-group {
    display: flex !important;
}

.flex {
    display: flex;
}

.flex-r {
    flex-direction: row;
}
.flex-c {
    flex-direction: column;
}
.flex-jc-sa {
    justify-content: space-around;
}
.flex-jc-sb {
    justify-content: space-between;
}
.flex-ac {
    align-items: center;
}
.flex-j {
    justify-content: center;
}
.flex-j-sa {
    justify-content: space-around;
}
.flex-j-sb {
    justify-content: space-between;
}

.flex-a {
    align-items: center;
}
.flex-m {
    align-items: center;
    justify-content: center;
}
.flex-sb {
    justify-content: space-between;
}
.flex-w {
    flex-wrap: wrap;
}

.el-tabs .el-tabs__header {
    margin-bottom: 0.1rem;
}
.el-tabs__active-bar {
    width: 2.3rem !important;
    /* width: 1.1rem !important; */
    /* transform: scaleX(0.5) !important; */
    background-color: #ff9201 !important;
    /* margin-left: 0.6rem !important; */
    font-weight: 400 !important;
}
.el-tabs__active-bar.is-active {
    font-weight: 600 !important;
}
.el-tabs__active-bar::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: -0.06rem;
    /* left: 0.5rem; */
    left: 1.06rem;
    border-bottom: 0.06rem solid #ff9201;
    border-left: 0.06rem solid transparent;
    border-right: 0.06rem solid transparent;
}
.el-tabs__nav {
    width: 100%;
}
.el-tabs__item {
    padding: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 2.3rem;
    text-align: center;
    color: #222 !important;
}
.el-tabs__item.is-active {
    padding: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-weight: bold !important;
    color: #ff9201 !important;
}

/* .el-tabs__item.is-top:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1px;
    height: 12px;
    transform: translateY(-26px);
    background-color: #ccc;
} */
.el-carousel .el-carousel__container {
    height: 1.7rem;
}
.el-carousel__indicator .el-carousel__button {
    width: 0.08rem !important;
    height: 0.08rem !important;
    border-radius: 0.08rem !important;
    padding: 0 !important;
}
.el-carousel__indicator--horizontal {
    padding: 0.08rem 0.04rem !important;
}
#fc_frame {
    z-index: 200 !important;
}
</style>

<template>
    <div id="app" ref="vueApp" :class="[$i18n.locale]">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive">
                <!-- 这里是会被的视图组件，比如 Home！ -->
            </router-view>
        </keep-alive>

        <router-view v-if="!$route.meta.keepAlive">
            <!-- 这里是不被的视图组件，比如 Edit！ -->
        </router-view>
    </div>
</template>

<script>
// import User from '@/models/user';
import Context from '@/utils/context';
// import Cookie from '@/utils/cookie';
export default {
    name: 'App',
    components: {},
    data() {
        return {};
    },
    mounted() {
        this.$refs.vueApp.style.disply = 'block';
        
    },
    created() {
        this.curAid = Context.getAid();
        localStorage.setItem('cg_aid', this.curAid);

        // 处理origin参数，到推广来源字段
        let url = new URL(location.href);
        let searchParams = url.search;
        if (!!searchParams && searchParams.indexOf('origin=') >= 0) {
            let origin = searchParams.split('origin=')[1].split('&')[0];
            localStorage.setItem('cg_origin', origin);
        }
        if (document.referrer) {
            localStorage.setItem('cg_referrer', document.referrer);
        }
    }
};
</script>
